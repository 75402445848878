/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:054298ff-5015-4d0b-91af-2dcd166fa2fb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_DnqhgWfp5",
    "aws_user_pools_web_client_id": "2i9j4ukek1aeiagp9i9pb21rva",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "nicotto-admin-hostingbucket-main",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d397l076v1qr6c.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://oacn1phwej.execute-api.ap-northeast-1.amazonaws.com/main",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://iokdgw6kd5cp3hi3v7pxukt5ky.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "nicotto-storage162047-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
